import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-358b467e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "el-dialog__title" }
const _hoisted_2 = { class: "el-upload__text" }
const _hoisted_3 = { class: "media" }
const _hoisted_4 = { class: "el-upload__tip media-content" }
const _hoisted_5 = {
  key: 1,
  "no-border": true,
  class: "image-upload"
}
const _hoisted_6 = { class: "image-upload__cropper-wrapper" }
const _hoisted_7 = {
  key: 0,
  class: "image-upload__file-type"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_cropper = _resolveComponent("cropper")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.showSettings,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showSettings) = $event)),
    width: "calc(var(--app-width) * 0.8)",
    "before-close": _ctx.handleClose
  }, {
    header: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('shared.organism.imageUploader.header')), 1)
    ]),
    footer: _withCtx(() => [
      (_ctx.uploadData)
        ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            class: "deactivate",
            onClick: _ctx.saveChanges
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('shared.organism.imageUploader.save')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      (!_ctx.uploadData)
        ? (_openBlock(), _createBlock(_component_el_upload, {
            key: 0,
            action: "#",
            drag: "",
            "before-upload": _ctx.beforeUpload,
            "http-request": _ctx.uploadFile,
            accept: "image/png, image/jpeg, image/jpg, image/gif",
            class: "el-upload"
          }, {
            tip: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('shared.organism.imageUploader.uploadTip')), 1),
                (_ctx.supportPasteFromClipboard)
                  ? (_openBlock(), _createBlock(_component_el_button, {
                      key: 0,
                      class: "media-right",
                      circle: "",
                      type: "primary",
                      onClick: _ctx.pasteFromClipboard
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: "paste" })
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, { icon: "upload" }),
              _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('shared.organism.imageUploader.uploadText')), 1)
            ]),
            _: 1
          }, 8, ["before-upload", "http-request"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_cropper, {
                ref: "cropper",
                src: _ctx.uploadData?.url,
                class: "image-upload__cropper",
                "check-orientation": "",
                canvas: {
            minWidth: 0,
            maxWidth: 1024,
          },
                "auto-zoom": true,
                "default-size": _ctx.defaultSize
              }, null, 8, ["src", "default-size"]),
              _createElementVNode("div", {
                class: "image-upload__reset-button",
                title: "Reset Image",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.reset()))
              }, [
                _createVNode(_component_font_awesome_icon, { icon: "rotate-right" })
              ]),
              (_ctx.uploadData?.type)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.uploadData.type), 1))
                : _createCommentVNode("", true)
            ])
          ]))
    ]),
    _: 1
  }, 8, ["modelValue", "before-close"]))
}