import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49cceae0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "highscoreContainer"
}
const _hoisted_2 = { class: "highscoreModuleSelection" }
const _hoisted_3 = { class: "el-dropdown-link" }
const _hoisted_4 = { class: "oneLineText highscoreModuleName" }
const _hoisted_5 = { class: "participant-count" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_Highscore = _resolveComponent("Highscore")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_ctx.hasData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableElements, (axis, index) => {
          return (_openBlock(), _createBlock(_component_el_card, {
            key: axis?.taskData.taskId,
            class: _normalizeClass(["highScoreSelectionContainer", { addOn__boarder: !axis }]),
            shadow: "never",
            "body-style": "text-align: center"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                (_ctx.hasAxes)
                  ? (_openBlock(), _createBlock(_component_el_dropdown, {
                      key: 0,
                      onCommand: (command) => _ctx.updateTableElements(index, command),
                      trigger: "click",
                      placement: "bottom"
                    }, {
                      dropdown: _withCtx(() => [
                        _createVNode(_component_el_dropdown_menu, null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.axes, (ax, index) => {
                              return (_openBlock(), _createElementBlock(_Fragment, {
                                key: ax.taskData.taskId
                              }, [
                                (_ctx.isTopicHeading(index))
                                  ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                      key: 0,
                                      class: "heading oneLineText",
                                      divided: true,
                                      style: { pointerEvents: 'none' },
                                      disabled: ""
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(ax.taskData.topicName), 1)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : _createCommentVNode("", true),
                                _createVNode(_component_el_dropdown_item, {
                                  command: ax,
                                  divided: _ctx.isTopicHeading(index)
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_font_awesome_icon, {
                                      class: "axisIcon",
                                      icon: _ctx.getIconOfAxis(ax),
                                      style: _normalizeStyle({ color: _ctx.getColorOfAxis(ax) })
                                    }, null, 8, ["icon", "style"]),
                                    _createElementVNode("span", null, " " + _toDisplayString(ax.taskData.taskName), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["command", "divided"])
                              ], 64))
                            }), 128))
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_3, [
                          axis
                            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                key: 0,
                                class: "highscoreModuleIcon",
                                icon: _ctx.getIconOfAxis(axis),
                                style: _normalizeStyle({ color: _ctx.getColorOfAxis(axis) })
                              }, null, 8, ["icon", "style"]))
                            : _createCommentVNode("", true),
                          _createElementVNode("p", _hoisted_4, [
                            _createTextVNode(_toDisplayString(axis ? axis.taskData.taskName : 'Select Task') + " ", 1),
                            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'angle-down'] }),
                            _createElementVNode("span", _hoisted_5, [
                              _createVNode(_component_font_awesome_icon, { icon: "user" }),
                              _createTextVNode(" " + _toDisplayString(_ctx.getParticipantCount(axis)), 1)
                            ])
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1032, ["onCommand"]))
                  : _createCommentVNode("", true),
                axis
                  ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                      key: 1,
                      icon: ['fas', 'trash'],
                      class: "trashButton",
                      onClick: ($event: any) => (_ctx.removeFromTableElements(index))
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              axis
                ? (_openBlock(), _createBlock(_component_Highscore, {
                    key: 0,
                    class: "highscore",
                    "task-id": axis.taskData.taskId,
                    "table-data": _ctx.filterParticipantData(axis.taskData.taskId),
                    selectedParticipantIds: _ctx.participantIds,
                    "onUpdate:selectedParticipantIds": [
                      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.participantIds) = $event)),
                      _ctx.updateSelectedParticipantIds
                    ],
                    "translation-path": _ctx.getTranslationPath(axis)
                  }, null, 8, ["task-id", "table-data", "selectedParticipantIds", "onUpdate:selectedParticipantIds", "translation-path"]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["class"]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}