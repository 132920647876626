import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, withModifiers as _withModifiers, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41ca1c08"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: { display: 'flex' } }
const _hoisted_2 = { class: "el-dialog__title" }
const _hoisted_3 = { style: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            paddingRight: '1rem',
          } }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "analyticsContainer" }
const _hoisted_6 = {
  key: 0,
  class: "pdf-preview"
}
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tree = _resolveComponent("el-tree")!
  const _component_analytics = _resolveComponent("analytics")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    ref: "analyticsContainer",
    modelValue: _ctx.showSettings,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showSettings) = $event)),
    "before-close": _ctx.handleClose,
    width: "calc(var(--app-width) * 0.8)"
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.$t('moderator.organism.settings.analytics.header')) + " ", 1),
          (_ctx.selectedTasks.length > 0)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                onClick: _ctx.exportToPDF,
                class: "exportButton",
                loading: _ctx.isConverting
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, { icon: ['fas', 'file-export'] })
                ]),
                _: 1
              }, 8, ["onClick", "loading"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_component_el_tree, {
        class: "tree",
        ref: "tree",
        key: _ctx.treeDataKey,
        style: {"max-width":"600px"},
        data: _ctx.treeData,
        "node-key": "id",
        "default-checked-keys": _ctx.selectedKeys,
        props: {
        children: 'tasks',
        label: (treeData) => treeData.name || treeData.title,
        disabled: false,
      },
        "show-checkbox": "",
        onCheckChange: _ctx.onCheckChange
      }, {
        default: _withCtx(({ data }) => [
          _createElementVNode("span", _hoisted_3, [
            _createElementVNode("span", null, [
              (data.taskType)
                ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                    key: 0,
                    icon: _ctx.getIconOfType(data.taskType.toLowerCase()),
                    style: _normalizeStyle({
                color: _ctx.getColorOfType(data.taskType.toLowerCase()),
              })
                  }, null, 8, ["icon", "style"]))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(data.name || data.title), 1)
            ]),
            (data.participantCount >= 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                  _createTextVNode(_toDisplayString(data.participantCount) + " ", 1),
                  _createVNode(_component_font_awesome_icon, { icon: ['fas', 'user'] })
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["data", "default-checked-keys", "props", "onCheckChange"])),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_analytics, {
          ref: "analyticsComponent",
          "session-id": _ctx.sessionId,
          "received-tasks": _ctx.selectedTasks,
          topics: _ctx.topics
        }, null, 8, ["session-id", "received-tasks", "topics"]),
        _createVNode(_Transition, { name: "transition-anim" }, {
          default: _withCtx(() => [
            (_ctx.pdfFile)
              ? (_openBlock(), _createElementBlock("section", _hoisted_6, [
                  _createElementVNode("button", {
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.closePreview()), ["self"]))
                  }, "×"),
                  _createElementVNode("iframe", {
                    src: _ctx.pdfFile,
                    width: "100%",
                    height: "100%"
                  }, null, 8, _hoisted_7)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "before-close"]))
}