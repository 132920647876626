import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-68082bf6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "el-dropdown-link" }
const _hoisted_2 = { class: "oneLineText highscoreModuleName" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_ctx.hasElements)
    ? (_openBlock(), _createBlock(_component_el_dropdown, {
        key: 0,
        onCommand: _ctx.addToElementsArray,
        trigger: "click",
        placement: "bottom"
      }, {
        dropdown: _withCtx(() => [
          _createVNode(_component_el_dropdown_menu, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableElements, (el, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: el.taskData.taskId
                }, [
                  (_ctx.isTopicHeading(index))
                    ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                        key: 0,
                        class: "heading oneLineText",
                        divided: true,
                        style: { pointerEvents: 'none' },
                        disabled: ""
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(el.taskData.topicName), 1)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  _createVNode(_component_el_dropdown_item, {
                    command: el,
                    divided: _ctx.isTopicHeading(index)
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        class: "axisIcon",
                        icon: _ctx.getIconOfElement(el),
                        style: _normalizeStyle({ color: _ctx.getColorOfElement(el) })
                      }, null, 8, ["icon", "style"]),
                      _createElementVNode("span", null, " " + _toDisplayString(el.taskData.taskName), 1)
                    ]),
                    _: 2
                  }, 1032, ["command", "divided"])
                ], 64))
              }), 128))
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("p", _hoisted_2, [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'plus'],
                class: "plus-icon"
              })
            ])
          ])
        ]),
        _: 1
      }, 8, ["onCommand"]))
    : _createCommentVNode("", true)
}