import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-772d80a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "participantSelectionContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.participants, (participant) => {
      return (_openBlock(), _createBlock(_component_font_awesome_icon, {
        key: participant.id,
        icon: participant.avatar.symbol,
        style: _normalizeStyle({
        color: _ctx.getParticipantColor(participant),
        transform: _ctx.getParticipantScale(participant),
      }),
        class: "participant",
        onClick: ($event: any) => (_ctx.participantSelectionChanged(participant.id))
      }, null, 8, ["icon", "style", "onClick"]))
    }), 128))
  ]))
}