import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-905e0132"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["element-loading-background", "element-loading-text"]
const _hoisted_2 = {
  key: 0,
  class: "participantSelection"
}
const _hoisted_3 = { class: "AnalyticsParallelCoordinates" }
const _hoisted_4 = { class: "AnalyticsTables" }
const _hoisted_5 = { class: "stackedBarChartContainer" }
const _hoisted_6 = { class: "RadarChartContainer" }
const _hoisted_7 = {
  key: 0,
  class: "heading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_participant_selection = _resolveComponent("participant-selection")!
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_parallel_coordinates = _resolveComponent("parallel-coordinates")!
  const _component_task_selection_dropdown = _resolveComponent("task-selection-dropdown")!
  const _component_ToolTip = _resolveComponent("ToolTip")!
  const _component_Tables = _resolveComponent("Tables")!
  const _component_StackedBarChartSelection = _resolveComponent("StackedBarChartSelection")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_radar_chart = _resolveComponent("radar-chart")!
  const _component_el_collapse = _resolveComponent("el-collapse")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    id: "analytics",
    style: { marginTop: '3rem' },
    "element-loading-background": 'var(--color-background)',
    "element-loading-text": _ctx.loadingTextNoTasks
  }, [
    _withDirectives((_openBlock(), _createBlock(_component_el_collapse, {
      modelValue: _ctx.activeNames,
      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.activeNames) = $event)),
      "element-loading-background": 'var(--color-background)',
      "element-loading-text": _ctx.loadingText
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_collapse_item, { name: "participantSelection" }, {
          title: _withCtx(() => [
            _createElementVNode("span", {
              onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
            }, _toDisplayString(_ctx.$t('moderator.organism.analytics.participantSelection.title')), 1)
          ]),
          default: _withCtx(() => [
            (!_ctx.loadingSteps)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_participant_selection, {
                    participants: _ctx.participants,
                    selectedParticipantIds: _ctx.selectedParticipantIds,
                    "onUpdate:selectedParticipantIds": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedParticipantIds) = $event)),
                    onParticipantSelected: _ctx.participantSelectionChanged,
                    style: _normalizeStyle({ borderBottom: '2px solid var(--color-background-dark)' })
                  }, null, 8, ["participants", "selectedParticipantIds", "onParticipantSelected", "style"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_el_collapse_item, { name: "overview" }, {
          title: _withCtx(() => [
            _createElementVNode("span", {
              onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
            }, _toDisplayString(_ctx.$t('moderator.organism.analytics.parallelCoordinates.title')), 1)
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.hasAxesAndData)
                ? (_openBlock(), _createBlock(_component_parallel_coordinates, {
                    key: 0,
                    "chart-axes": _ctx.availableAxes,
                    "participant-data": _ctx.dataEntries,
                    steps: _ctx.steps,
                    selectedParticipantIds: _ctx.selectedParticipantIds,
                    "onUpdate:selectedParticipantIds": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedParticipantIds) = $event)),
                    onParticipantSelected: _ctx.participantSelectionChanged,
                    style: _normalizeStyle({ opacity: _ctx.loadingSteps ? 0 : 1 })
                  }, null, 8, ["chart-axes", "participant-data", "steps", "selectedParticipantIds", "onParticipantSelected", "style"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_collapse_item, {
          name: "tables",
          class: "pdfPageBreakElement dropDownTables",
          style: _normalizeStyle({
          width: _ctx.tableElements.length > 1 ? '100%' : '50%',
        })
        }, {
          title: _withCtx(() => [
            _createElementVNode("span", {
              onClick: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop"]))
            }, [
              _createTextVNode(_toDisplayString(_ctx.$t('moderator.organism.analytics.tables.title')) + " ", 1),
              _createVNode(_component_ToolTip, {
                text: _ctx.$t('moderator.organism.analytics.tables.selectTask')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_task_selection_dropdown, {
                    "available-elements": _ctx.availableAxes,
                    elements: _ctx.tableElements,
                    "onUpdate:elements": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.tableElements) = $event))
                  }, null, 8, ["available-elements", "elements"])
                ]),
                _: 1
              }, 8, ["text"])
            ])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              (_ctx.hasAxesAndData)
                ? (_openBlock(), _createBlock(_component_Tables, {
                    key: 0,
                    "participant-data": _ctx.dataEntries,
                    axes: _ctx.availableAxes,
                    steps: _ctx.steps,
                    tableElements: _ctx.tableElements,
                    "onUpdate:tableElements": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.tableElements) = $event)),
                    selectedParticipantIds: _ctx.selectedParticipantIds,
                    "onUpdate:selectedParticipantIds": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.selectedParticipantIds) = $event)),
                    style: _normalizeStyle({ opacity: _ctx.loadingSteps ? 0 : 1 })
                  }, null, 8, ["participant-data", "axes", "steps", "tableElements", "selectedParticipantIds", "style"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["style"]),
        (_ctx.surveyData.length > 0)
          ? (_openBlock(), _createBlock(_component_el_collapse_item, {
              key: 0,
              name: "surveysQuizzes",
              class: "pdfPageBreakElement dropDownSurveysQuizzes",
              style: _normalizeStyle({
          width: _ctx.surveyElements.length > 1 ? '100%' : '50%',
        })
            }, {
              title: _withCtx(() => [
                _createElementVNode("span", {
                  onClick: _cache[9] || (_cache[9] = _withModifiers(() => {}, ["stop"]))
                }, [
                  _createTextVNode(_toDisplayString(_ctx.$t('moderator.organism.analytics.stackedBarCharts.title')) + " ", 1),
                  _createVNode(_component_ToolTip, {
                    text: 
                _ctx.$t('moderator.organism.analytics.stackedBarCharts.selectTask')
              
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_task_selection_dropdown, {
                        "available-elements": _ctx.surveyData,
                        elements: _ctx.surveyElements,
                        "onUpdate:elements": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.surveyElements) = $event))
                      }, null, 8, ["available-elements", "elements"])
                    ]),
                    _: 1
                  }, 8, ["text"])
                ])
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  (_ctx.hasSurveyData)
                    ? (_openBlock(), _createBlock(_component_StackedBarChartSelection, {
                        key: 0,
                        "survey-data": _ctx.surveyData,
                        selectedParticipantIds: _ctx.selectedParticipantIds,
                        "onUpdate:selectedParticipantIds": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.selectedParticipantIds) = $event)),
                        "survey-elements": _ctx.surveyElements,
                        "onUpdate:surveyElements": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.surveyElements) = $event)),
                        style: _normalizeStyle({ opacity: _ctx.loadingSteps ? 0 : 1 })
                      }, null, 8, ["survey-data", "selectedParticipantIds", "survey-elements", "style"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }, 8, ["style"]))
          : _createCommentVNode("", true),
        (_ctx.radarDataEntries.length > 0)
          ? (_openBlock(), _createBlock(_component_el_collapse_item, {
              key: 1,
              name: "radarCharts",
              class: "pdfPageBreakElement"
            }, {
              title: _withCtx(() => [
                _createElementVNode("span", {
                  onClick: _cache[12] || (_cache[12] = _withModifiers(() => {}, ["stop"]))
                }, _toDisplayString(_ctx.$t('moderator.organism.analytics.radarCharts.title')), 1)
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.radarDataEntries, (entry, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "radarChart",
                      key: 'radarChart' + index,
                      style: _normalizeStyle({ opacity: _ctx.loadingSteps ? 0 : 1 })
                    }, [
                      (entry.title)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_7, [
                            _createVNode(_component_font_awesome_icon, {
                              class: "headingIcon",
                              icon: _ctx.getIconOfType(_ctx.TaskType.INFORMATION),
                              style: _normalizeStyle({ color: _ctx.getColorOfType(_ctx.TaskType.INFORMATION) })
                            }, null, 8, ["icon", "style"]),
                            _createTextVNode(" " + _toDisplayString(entry.title), 1)
                          ]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_radar_chart, {
                        labels: entry.labels,
                        datasets: entry.data,
                        test: entry.test,
                        title: entry.title,
                        size: 300,
                        levels: 5,
                        defaultColor: 'var(--color-dark-contrast-light)',
                        selectedParticipantIds: _ctx.selectedParticipantIds,
                        "onUpdate:selectedParticipantIds": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.selectedParticipantIds) = $event)),
                        onParticipantSelected: _ctx.participantSelectionChanged
                      }, null, 8, ["labels", "datasets", "test", "title", "defaultColor", "selectedParticipantIds", "onParticipantSelected"])
                    ], 4))
                  }), 128))
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue", "element-loading-background", "element-loading-text"])), [
      [_directive_loading, _ctx.loadingSteps]
    ])
  ], 8, _hoisted_1)), [
    [_directive_loading, _ctx.isLoading]
  ])
}