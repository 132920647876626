import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f10ed1c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "el-dialog__title" }
const _hoisted_2 = {
  key: 1,
  "no-border": true,
  class: "image-upload"
}
const _hoisted_3 = { class: "image-upload__cropper-wrapper" }
const _hoisted_4 = { class: "level" }
const _hoisted_5 = {
  key: 0,
  class: "level-left"
}
const _hoisted_6 = {
  key: 1,
  class: "level-left"
}
const _hoisted_7 = { class: "level-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_drawing_canvas = _resolveComponent("vue-drawing-canvas")!
  const _component_cropper = _resolveComponent("cropper")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_color_picker = _resolveComponent("el-color-picker")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_ImageUploader = _resolveComponent("ImageUploader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.showSettings,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showSettings) = $event)),
      width: "calc(var(--app-width) * 0.8)",
      "before-close": _ctx.handleClose,
      class: "drawing-dialog"
    }, {
      header: _withCtx(() => [
        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('shared.organism.drawingUploader.header')), 1)
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          (!_ctx.crop)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_el_button, {
                  circle: "",
                  type: "primary",
                  onClick: _ctx.reset
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, { icon: "trash" })
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_el_button, {
                  circle: "",
                  type: "primary",
                  onClick: _ctx.undo
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, { icon: "rotate-left" })
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_el_button, {
                  circle: "",
                  type: "primary",
                  onClick: _ctx.redo
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, { icon: "rotate-right" })
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                (!_ctx.eraser)
                  ? (_openBlock(), _createBlock(_component_el_button, {
                      key: 0,
                      circle: "",
                      type: "primary",
                      onClick: _ctx.setEraser
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: "eraser" })
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                (_ctx.eraser)
                  ? (_openBlock(), _createBlock(_component_el_button, {
                      key: 1,
                      circle: "",
                      type: "primary",
                      onClick: _ctx.setPen
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: "pen" })
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_el_color_picker, {
                  modelValue: _ctx.color,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.color) = $event))
                }, null, 8, ["modelValue"]),
                _createVNode(_component_el_button, {
                  circle: "",
                  type: "primary",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showBackgroundUploadDialog = true))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, { icon: "image" })
                  ]),
                  _: 1
                }),
                (_ctx.base64BackgroundImageUrl)
                  ? (_openBlock(), _createBlock(_component_el_button, {
                      key: 2,
                      circle: "",
                      type: "primary",
                      onClick: _ctx.deleteBackgroundImage
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: ['fac', 'no_image'] })
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_6)),
          _createElementVNode("div", _hoisted_7, [
            (_ctx.uploadData)
              ? (_openBlock(), _createBlock(_component_el_button, {
                  key: 0,
                  class: "deactivate",
                  onClick: _ctx.saveChanges
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('shared.organism.drawingUploader.save')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      default: _withCtx(() => [
        (!_ctx.crop)
          ? (_openBlock(), _createBlock(_component_vue_drawing_canvas, {
              key: 0,
              class: "drawing-canvas",
              ref: "VueCanvasDrawing",
              image: _ctx.uploadData,
              "onUpdate:image": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.uploadData) = $event)),
              width: _ctx.canvasWidth,
              height: _ctx.canvasHeight,
              color: _ctx.color,
              lineWidth: _ctx.lineWidth,
              eraser: _ctx.eraser,
              "background-image": _ctx.base64BackgroundImageUrl,
              "background-color": "#ffffff"
            }, null, 8, ["image", "width", "height", "color", "lineWidth", "eraser", "background-image"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_cropper, {
                  ref: "cropper",
                  src: _ctx.uploadData,
                  class: "image-upload__cropper",
                  "check-orientation": "",
                  canvas: {
            minWidth: 0,
            maxWidth: 1024,
          },
                  "auto-zoom": true,
                  "default-size": _ctx.defaultSize
                }, null, 8, ["src", "default-size"])
              ])
            ]))
      ]),
      _: 1
    }, 8, ["modelValue", "before-close"]),
    _createVNode(_component_ImageUploader, {
      "show-modal": _ctx.showBackgroundUploadDialog,
      "onUpdate:showModal": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showBackgroundUploadDialog) = $event)),
      modelValue: _ctx.base64BackgroundImageUrl,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.base64BackgroundImageUrl) = $event)),
      onImageChanged: _ctx.backgroundImageChanged
    }, null, 8, ["show-modal", "modelValue", "onImageChanged"])
  ], 64))
}